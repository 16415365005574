import { Injectable } from '@angular/core'
import { CollectionRepository, FirestoreQueryBuilder } from '@engineering11/web-api-firebase'
import { map } from 'rxjs/operators'
import { COLLECTIONS, ISellerApplication } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class SellerApplicationRepository extends CollectionRepository<ISellerApplication> {
  protected COLLECTION = COLLECTIONS.SELLER_APPLICATION

  listenByCustomerKey(customerKey: string) {
    const queryBuilder = new FirestoreQueryBuilder<ISellerApplication>().where('customerKey', '==', customerKey)
    const result = this.queryValueChanges(queryBuilder.build())
    return result.pipe(map(result => (result ? result[0] : undefined)))
  }
}
