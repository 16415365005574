import { Injectable } from '@angular/core'
import { Params, Router } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class SellerNavigationService {
  constructor(private router: Router) {}

  toListings() {
    return this.router.navigate(['sell/for-sale'])
  }

  toListing(itemId: string) {
    return this.router.navigate(['sell/for-sale', itemId])
  }
}
