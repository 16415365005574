// Framework
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { E11BackdropModule } from '@engineering11/ui-lib/e11-backdrop'
// E11 Libraries
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11CarouselModule } from '@engineering11/ui-lib/e11-carousel'
import { E11ConfirmationModule } from '@engineering11/ui-lib/e11-confirmation'
import { E11DividerModule } from '@engineering11/ui-lib/e11-divider'
import { E11ImageModule } from '@engineering11/ui-lib/e11-image'
import { E11ImagesModule } from '@engineering11/ui-lib/e11-images'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { E11LoaderDotsModule } from '@engineering11/ui-lib/e11-loader-dots'
import { E11PanelModule } from '@engineering11/ui-lib/e11-panel'
import { E11PhoneNumberModule } from '@engineering11/ui-lib/e11-phone-number'
import { E11ProfilePicModule } from '@engineering11/ui-lib/e11-profile-pic'
import { E11SelectModule } from '@engineering11/ui-lib/e11-select'
import { E11SideOverModule } from '@engineering11/ui-lib/e11-side-over'
import { E11TextareaModule } from '@engineering11/ui-lib/e11-textarea'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { E11IconModule } from '@engineering11/ui-lib/e11-icon'
import { E11TabsModule } from '@engineering11/ui-lib/e11-tabs'

// Platform specific
import { UiAccordionModule } from '@engineering11/ui/ui-accordion'
import { UiBadgeModule } from '@engineering11/ui/ui-badge'
import { UiButtonModule } from '@engineering11/ui/ui-button'
import { UiChartPercentageModule } from '@engineering11/ui/ui-chart-percentage'
import { UiDatatableModule } from '@engineering11/ui/ui-datatable'
import { UiDividerModule } from '@engineering11/ui/ui-divider'
import { UiInputModule } from '@engineering11/ui/ui-input'
import { UiInputCheckboxModule } from '@engineering11/ui/ui-input-checkbox'
import { UiInputRadioModule } from '@engineering11/ui/ui-input-radio'
import { UiLanguagesModule } from '@engineering11/ui/ui-languages'
import { UiLoaderModule } from '@engineering11/ui/ui-loader'
import { UiLoaderDotsModule } from '@engineering11/ui/ui-loader-dots'
import { UiLoaderLineModule } from '@engineering11/ui/ui-loader-line'
import { UiModalModule } from '@engineering11/ui/ui-modal'
import { UiNavHorizontalModule } from '@engineering11/ui/ui-nav-horizontal'
import { UiNavSideModule } from '@engineering11/ui/ui-nav-side'
import { UiPanelModule } from '@engineering11/ui/ui-panel'
import { UiPanelClassicModule } from '@engineering11/ui/ui-panel-classic'
import { UiSelectModule } from '@engineering11/ui/ui-select'
import { UiSelectNativeModule } from '@engineering11/ui/ui-select-native'
import { UiSelectorModule } from '@engineering11/ui/ui-selector'
import { UiSideDrawerModule } from '@engineering11/ui/ui-side-drawer'
import { UiSliderModule } from '@engineering11/ui/ui-slider'
import { UiSwitchModule } from '@engineering11/ui/ui-switch'
import { UiTagModule } from '@engineering11/ui/ui-tag'
import { UiTextareaModule } from '@engineering11/ui/ui-textarea'
import { UiTimelineModule } from '@engineering11/ui/ui-timeline'
import { UiViewBandModule } from '@engineering11/ui/ui-view-band'
import { E11InputLabelModule } from '@engineering11/ui-lib/e11-input-label'
import { E11EmptyStateModule } from '@engineering11/ui-lib/e11-empty-state'
import { E11InputErrorsModule } from '@engineering11/ui-lib/e11-input-errors'
import { E11BadgeModule } from '@engineering11/ui-lib/e11-badge'
import { E11AutocompleteModule } from '@engineering11/ui-lib/e11-autocomplete'
import { E11PaginatorModule } from '@engineering11/ui-lib/e11-paginator'

const ImportExportModules = [
  // New E11 Modules
  E11BadgeModule,
  E11ButtonModule,
  E11DividerModule,
  E11TextareaModule,
  E11CarouselModule,
  E11TooltipModule,
  E11ProfilePicModule,
  E11PanelModule,
  E11LoaderDotsModule,
  E11ConfirmationModule,
  E11BackdropModule,
  E11SelectModule,
  E11PhoneNumberModule,
  E11ImageModule,
  E11SideOverModule,
  E11IconModule,
  E11TabsModule,
  E11InputLabelModule,
  E11InputErrorsModule,
  E11EmptyStateModule,
  E11ImagesModule,
  E11AutocompleteModule,
  E11PaginatorModule,
  // Old UI Modules
  UiAccordionModule,
  UiBadgeModule,
  UiButtonModule,
  UiChartPercentageModule,
  UiDividerModule,
  UiInputModule,
  UiInputCheckboxModule,
  UiInputRadioModule,
  UiLanguagesModule,
  UiModalModule,
  UiPanelModule,
  UiPanelClassicModule,
  UiSelectModule,
  UiSelectorModule,
  UiSelectNativeModule,
  UiSliderModule,
  UiSwitchModule,
  UiTagModule,
  UiLoaderModule,
  UiLoaderLineModule,
  UiLoaderDotsModule,
  UiTimelineModule,
  UiViewBandModule,
  UiNavHorizontalModule,
  UiNavSideModule,
  UiTextareaModule,
  UiDatatableModule,
  UiSideDrawerModule,

  // REMEMBER: Toast and Notifications are root level
]

@NgModule({
  declarations: [],
  imports: [CommonModule, ...ImportExportModules, E11InputModule.forRoot({ maxLengthByContext: {}, numericWheelStrategy: 'blur' })],
  exports: [...ImportExportModules, E11InputModule],
})
export class UiComponentLibraryModule {}
