import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, NgModule, SecurityContext } from '@angular/core'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAuthModule, TENANT_ID } from '@angular/fire/compat/auth'
import { AngularFirestoreModule } from '@angular/fire/compat/firestore'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { GoogleMapsModule } from '@angular/google-maps'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CloudflareStreamModule } from '@cloudflare/stream-angular'
import { ConsumerTenantBootstrapConfigProvider } from '@consumer/app/config/tenant.config'
import * as fromStore from '@consumer/app/store/index'
import { FilesModule } from '@engineering11/files-web'
import { MapsModule } from '@engineering11/maps-web'
import { PermissionsModule } from '@engineering11/permissions-web'
import { AppConfigService, TenantBootstrapModule, appConfigInitializerFn } from '@engineering11/tenant-bootstrap-web'
import { E11NotificationsModule } from '@engineering11/ui-lib/e11-notifications'
import { E11SideOverModule } from '@engineering11/ui-lib/e11-side-over'
import { E11ToastModule } from '@engineering11/ui-lib/e11-toast'
import { UiBackdropModule } from '@engineering11/ui/ui-backdrop'
import { UiErrorsModule } from '@engineering11/ui/ui-errors'
import { UiNotificationsModule } from '@engineering11/ui/ui-notifications'
import { E11ErrorModule, GlobalErrorHandler } from '@engineering11/web-api-error'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { NgAisModule } from 'angular-instantsearch'
import firebase from 'firebase/compat/app'
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger'
import { MarkdownModule } from 'ngx-markdown'
import { WebpackTranslateLoader, errorHandlerOverrides } from 'shared-lib'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AboutComponent } from './components/about/about.component'
import { ToastComponent } from './components/toast/toast.component'
import { ISDFilesConfigProvider } from './config/files.config'
import { componentStateReducer } from './config/link-global.state'
import { PERMISSION_TO_ROLE, UserRolesProvider } from './config/permission.config'
import { ServerErrorInterceptor } from './error-server.interceptor'
import { DenyGuard } from './guards/deny.guard'
import { httpInterceptorProviders } from './http-interceptors'
import { FileNotFoundModule } from './modules/_404/404.module'
import { UiComponentLibraryModule } from './modules/_component-library/component-library.module'
import { SharedModule } from './modules/_shared/shared.module'
import { AuthenticationModule } from './modules/authentication/authentication.module'
import { HomeModule } from './modules/home/home.module'
import { NotificationModule } from './modules/notification/notification.module'
import { RegistrationModule } from './modules/registration/registration.module'
import { rollbarFactory } from './rollbar'
import { ConfigService } from './services/config.service'
import { E11AlertNotificationService } from '@engineering11/web-ui-helpers'
import { AccessModule } from '@engineering11/access-web'
import { AccessConfigProvider } from './config/access.config'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}
export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig()
}

try {
  firebase.firestore().settings({ ignoreUndefinedProperties: true, merge: true })
} catch (e) {}

@NgModule({
  declarations: [AppComponent, AboutComponent, ToastComponent],
  imports: [
    NgAisModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
        // useFactory: HttpLoaderFactory,
        // deps: [HttpClient],
      },
    }),
    SharedModule,
    UiComponentLibraryModule,
    CloudflareStreamModule,
    RecaptchaModule,
    AuthenticationModule,
    RegistrationModule,
    HomeModule,
    NotificationModule,
    FileNotFoundModule,
    UiBackdropModule.forRoot(),
    UiErrorsModule.forRoot(),
    UiNotificationsModule.forRoot(),
    E11ToastModule.forRoot(),
    E11NotificationsModule.forRoot(),
    E11SideOverModule,

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    HttpClientModule,

    LoggerModule.forRoot({ level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF, serverLogLevel: NgxLoggerLevel.OFF }),
    StoreModule.forRoot({ ...fromStore.reducers, componentStore: componentStateReducer }),
    EffectsModule.forRoot(fromStore.effects),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    FilesModule.forRoot({ configProvider: ISDFilesConfigProvider }),
    GoogleMapsModule,
    MapsModule.forRoot({ mapsApiKey: environment.firebaseConfig.apiKey }),
    PermissionsModule.forRoot({ userRolesProvider: UserRolesProvider, permissionToRole: PERMISSION_TO_ROLE }),
    E11ErrorModule.forRoot({
      errorTrackerFactory: rollbarFactory,
      logger: NGXLogger,
      errorHandlerMap: errorHandlerOverrides,
      alertService: E11AlertNotificationService,
    }),
    TenantBootstrapModule.forRoot({ configProvider: ConsumerTenantBootstrapConfigProvider }),
    AccessModule.forRoot({ configProvider: AccessConfigProvider }),
  ],
  providers: [
    DenyGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true,
    },
    httpInterceptorProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: TENANT_ID, useValue: environment.tenantId },
    {
      provide: RECAPTCHA_SETTINGS,
      useFactory: (appConfig: AppConfigService) => {
        return { siteKey: appConfig.config.auth.reCaptchaSiteKey }
      },
      deps: [AppConfigService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
