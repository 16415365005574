import { Injectable } from '@angular/core'
import { ContentItemService, IContentPublishLog } from '@engineering11/content-web'
import { IUser } from '@engineering11/user-shared'
import { ICompleteProductContentItem, ICompleteProductItem, IProductContentItem, ItemStatus, productItemContentType } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class ItemContentService extends ContentItemService<IProductContentItem> {
  async updateAndPublish(contentItem: ICompleteProductContentItem, user: IUser): Promise<ICompleteProductContentItem> {
    const status = ItemStatus.Published
    const document = { ...contentItem.document, status }
    const newItem = { ...contentItem, document }
    await this.update(newItem)
    await this.createPublishRequest(newItem.document, user)
    return newItem
  }
  // TODO: Consider whether to manage status on back end
  async delistItem(item: ICompleteProductContentItem, user: IUser): Promise<ICompleteProductContentItem> {
    const status = ItemStatus.Delisted
    const document = { ...item.document, status }
    const newItem = { ...item, document }
    await this.update(newItem)
    await this.createPublishRequest(newItem.document, user)
    return newItem
  }

  private createPublishRequest(item: ICompleteProductItem, user: IUser) {
    const publishLog: IContentPublishLog = {
      contentId: item.contentId,
      customerKey: user.customerKey,
      firstName: user.firstName,
      lastName: user.lastName,
      userId: user.id,
      timestamp: new Date(),
    }
    return this.publish.add(publishLog)
  }
}
