<div class="e11-w-full e11-flex e11-pb-4 e11-mb-4 e11-border-b e11-border-skin-grey/50">
  <div class="e11-w-1/5 e11-pr-4">
    <e11-images
      *ngIf="item.photos && item.photos.length"
      [imageUrl]="item.photos.length ? item.photos[0]?.url : ''"
      [width]="'100%'"
      [height]="'100%'"
      (click)="itemClick.emit()"
    ></e11-images>
  </div>
  <div class="e11-w-5/6">
    <div class="e11-w-full">
      <h3
        class="e11-line-clamp-1 e11-word-break marker:selection:e11-text-skin-primary hover:e11-text-skin-secondary hover:e11-cursor-pointer e11-mb-2 e11-pr-4"
        (click)="itemClick.emit()"
      >
        {{ item.title }}
      </h3>
      <div *ngIf="item.description" class="e11-line-clamp-2 e11-word-break e11-mb-2 e11-pr-4">{{ item.description }}</div>
    </div>
    <div class="e11-w-full e11-flex">
      <div class="e11-w-1/2">
        <h3 class="e11-mb-1 e11-text-skin-dark" *ngIf="item.amountInCents">${{ item.amountInCents | centsToDollars }}</h3>
        <div *ngIf="item.availableQuantity" class="e11-text-xs">({{ 'Qty' | translate }}: {{ item.availableQuantity }})</div>
      </div>
    </div>
  </div>
</div>
