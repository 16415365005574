import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { IProductContentItem } from 'shared-lib'
import { SellerItemActions, SellerItemActionTypes } from './seller-item.actions'

export interface State extends EntityState<IProductContentItem> {
  loadedItems: boolean
  selectedItemId: string | null
}

export const adapter: EntityAdapter<IProductContentItem> = createEntityAdapter<IProductContentItem>({
  selectId: productContentItem => productContentItem.contentId,
})

export const initialState: State = adapter.getInitialState({
  loadedItems: false,
  selectedItemId: null,
})

export function reducer(state = initialState, action: SellerItemActions) {
  switch (action.type) {
    case SellerItemActionTypes.getSellerItems:
      return { ...state }
    case SellerItemActionTypes.getSellerItemsSuccess:
      return adapter.setMany(action.payload, { ...state, loadedItems: true })
    case SellerItemActionTypes.selectItem:
      return { ...state, selectedItemId: action.payload }
    case SellerItemActionTypes.resetSelectedItem:
      return { ...state, selectedItemId: null }
    case SellerItemActionTypes.createItemSuccess:
      return adapter.addOne(action.payload, { ...state, selectedItemId: action.payload.contentId })
    case SellerItemActionTypes.deleteItemSuccess:
      return adapter.removeOne(action.payload.contentId, state)
    case SellerItemActionTypes.updateItemSuccess:
    case SellerItemActionTypes.publishItemSuccess:
    case SellerItemActionTypes.delistItemSuccess:
      return adapter.updateOne({ id: action.payload.contentId, changes: action.payload }, { ...state })
    default:
      return state
  }
}
