import { Injectable } from '@angular/core'
import { ItemContentService } from '@consumer/app/services/item/item-content.service'
import { SellerItemService } from '@consumer/app/services/seller-item.service'
import { deepCopy, isNotNil } from '@engineering11/utility'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { getCurrentUser, NotificationTranslateService, SELLER_ROLE } from 'shared-lib'
import {
  CreateItem,
  CreateItemSuccess,
  DeleteItem,
  DeleteItemSuccess,
  DelistItem,
  DelistItemSuccess,
  GetSellerItems,
  GetSellerItemsSuccess,
  NoSellerAction,
  OnInitSellerItems,
  PublishItem,
  PublishItemSuccess,
  SellerItemActionTypes,
  UpdateItem,
  UpdateItemSuccess,
} from './seller-item.actions'

@Injectable()
export class SellerItemEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private itemContentService: ItemContentService,
    private sellerItemService: SellerItemService,
    private notificationService: NotificationTranslateService
  ) {}

  ngrxOnInitEffects(): Action {
    return { type: SellerItemActionTypes.onInitSellerItems }
  }

  onInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OnInitSellerItems>(SellerItemActionTypes.onInitSellerItems),
      switchMap(_ => this.store.select(getCurrentUser)),
      filter(isNotNil),
      map(user => {
        if (user.roles.includes(SELLER_ROLE)) {
          return new GetSellerItems(user)
        } else {
          return new NoSellerAction()
        }
      })
    )
  })

  $onGetSellerItems = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSellerItems>(SellerItemActionTypes.getSellerItems),
      switchMap(action => {
        return this.sellerItemService.getAllProductItems(action.payload.customerKey)
      }),
      map(result => new GetSellerItemsSuccess(result))
    )
  )

  $onCreateSellerItem = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateItem>(SellerItemActionTypes.createItem),
      switchMap(action => {
        return this.itemContentService.add(deepCopy(action.payload))
      }),
      map(result => new CreateItemSuccess(result))
    )
  )

  $onUpdateSellerItem = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateItem>(SellerItemActionTypes.updateItem),
      switchMap(action => this.itemContentService.persist(deepCopy(action.payload as any))), // TODO: Fix typing
      map(result => new UpdateItemSuccess(result))
    )
  )

  $onPublishItem = createEffect(() =>
    this.actions$.pipe(
      ofType<PublishItem>(SellerItemActionTypes.publishItem),
      withLatestFrom(this.store.select(getCurrentUser).pipe(filter(isNotNil))),
      switchMap(async ([action, user]) => {
        return this.itemContentService.updateAndPublish(action.payload, user)
      }),
      tap(_ => {
        this.notificationService.popNotification({
          title: 'Item Published',
          message: 'You have published this as an active listing!',
          type: 'success',
          autoClose: true,
        })
      }),
      map(result => new PublishItemSuccess(result))
    )
  )

  $onDelistItem = createEffect(() =>
    this.actions$.pipe(
      ofType<DelistItem>(SellerItemActionTypes.delistItem),
      withLatestFrom(this.store.select(getCurrentUser).pipe(filter(isNotNil))),
      switchMap(async ([action, user]) => {
        return this.itemContentService.delistItem(action.payload, user)
      }),
      map(result => new DelistItemSuccess(result))
    )
  )

  $onDeleteItem = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteItem>(SellerItemActionTypes.deleteItem),
      withLatestFrom(this.store.select(getCurrentUser).pipe(filter(isNotNil))),
      switchMap(async ([action, user]) => {
        await this.itemContentService.summary.archiveWithId(action.payload.contentId)
        return action.payload
      }),
      map(result => new DeleteItemSuccess(result))
    )
  )
}
