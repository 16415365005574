import { Action } from '@ngrx/store'
import { IPlatformUser, ISellerApplicationDto, SellerApplicationStatus } from 'shared-lib'

export enum SellerApplicationActionTypes {
  onInitSellerApplication = '[Seller Application Effects] on init',
  getSellerApplicationStatus = '[On App Init] get seller application status',
  getSellerApplicationStatusSuccess = '[Seller Application Effects] get seller application status success',
  sendSellerApplication = '[Seller Application Page] send application',
  sendSellerApplicationSuccess = '[Seller Application Effects] send application success',
  sendSellerApplicationFailure = '[Seller Application Effects] send application failure',
}

export class OnInitSellerApplication implements Action {
  readonly type = SellerApplicationActionTypes.onInitSellerApplication
  constructor() {}
}

export class ListenSellerApplicationStatus implements Action {
  readonly type = SellerApplicationActionTypes.getSellerApplicationStatus
  constructor() {}
}

export class GetSellerApplicationStatusSuccess implements Action {
  readonly type = SellerApplicationActionTypes.getSellerApplicationStatusSuccess
  constructor(public payload: { status: SellerApplicationStatus }) {}
}

export class SendSellerApplication implements Action {
  readonly type = SellerApplicationActionTypes.sendSellerApplication
  constructor(public payload: ISellerApplicationDto) {}
}

export class SendSellerApplicationSuccess implements Action {
  readonly type = SellerApplicationActionTypes.sendSellerApplicationSuccess
  constructor() {}
}

export class SendSellerApplicationFailure implements Action {
  readonly type = SellerApplicationActionTypes.sendSellerApplicationFailure
  constructor() {}
}

export type SellerApplicationActions =
  | SendSellerApplication
  | SendSellerApplicationSuccess
  | SendSellerApplicationFailure
  | ListenSellerApplicationStatus
  | GetSellerApplicationStatusSuccess
