import { Injectable } from '@angular/core'
import { Params, Router } from '@angular/router'

export const SEARCH_QUERY_PARAM = 'searchCurrent'

@Injectable({ providedIn: 'root' })
export class ConsumerNavigationService {
  constructor(private router: Router) {}

  searchWith(searchTerm: string) {
    this.searchPage({ [SEARCH_QUERY_PARAM]: searchTerm })
  }

  private searchPage(queryParams?: Params) {
    this.router.navigate(['shop/search'], { queryParams })
  }
}
