import { ITenantConfig } from '../model/environment.model'

export interface DomainBuilder {
  build(serviceName: string): string
}

export class AppEngineDomainBuilder implements DomainBuilder {
  constructor(private projectId: string, private protocol = 'https') {}
  build(serviceName: string) {
    return `${this.protocol}://${serviceName}-rest-dot-${this.projectId}.uc.r.appspot.com`
  }
}

export class ApiGatewayDomainBuilder implements DomainBuilder {
  constructor(private projectHash: string, private protocol = 'https') {}
  build(serviceName: string) {
    return `${this.protocol}://${serviceName}-api-gateway-${this.projectHash}.uc.gateway.dev`
  }
}

export function buildTenantConfig(env: string): ITenantConfig {
  const root = env !== 'production' ? `${env}-` : ''
  return {
    appConfigBaseURL: `https://${root}content.instockdepot.com/assets/bootstrap`,
    defaultTenant: `${root}app.instockdepot.com`,
  }
}
