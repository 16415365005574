<div class="container-view">
  <app-header></app-header>
  <app-header-secondary></app-header-secondary>

  <main>
    <div class="container-content block">
      <div class="row">
        <div class="col-xs-12"></div>
        <h2>{{ 'Notifications' | translate }}</h2>
      </div>
      <div class="spacer-1rem"></div>
      <div class="row">
        <div class="col-md-9 col-xs-12">
          <ui-notification-long></ui-notification-long>
        </div>
      </div>
    </div>
  </main>

  <app-footer></app-footer>
</div>
