import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { ConsumerNavigationService } from '@consumer/app/services/consumer-navigation.service'
import { SellerService } from '@consumer/app/services/seller.service'
import { Store } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  formSearch = new UntypedFormGroup({})
  formSearchSubmitted = false

  searchCurrent = ''

  destroy$ = new Subject()

  constructor(private formBuilder: UntypedFormBuilder, private navigationService: ConsumerNavigationService, private logger: NGXLogger) {}

  ngOnInit(): void {
    this.createFormSearch()
  }
  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  createFormSearch() {
    this.formSearch = this.formBuilder.group({
      search: new UntypedFormControl(''),
    })
    this.formSearch.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
      this.searchCurrent = this.formSearch.value.search
    })
  }

  search() {
    const searchValue = this.formSearch.value.search
    this.navigationService.searchWith(searchValue)
  }
}
