// Framework
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

// Platform specific
// import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { UiBadgeModule } from '@engineering11/ui/ui-badge'
import { UiButtonModule } from '@engineering11/ui/ui-button'
import { UiChartPercentageModule } from '@engineering11/ui/ui-chart-percentage'
import { UiDatatableModule } from '@engineering11/ui/ui-datatable'
import { UiInputCheckboxModule } from '@engineering11/ui/ui-input-checkbox'
import { UiLoaderModule } from '@engineering11/ui/ui-loader'
import { UiLoaderDotsModule } from '@engineering11/ui/ui-loader-dots'
import { UiLoaderLineModule } from '@engineering11/ui/ui-loader-line'
import { UiSideDrawerModule } from '@engineering11/ui/ui-side-drawer'
import { UiTextareaModule } from '@engineering11/ui/ui-textarea'
import { UiPanelClassicModule } from '@engineering11/ui/ui-panel-classic'
import { UiPanelModule } from '@engineering11/ui/ui-panel'

const ImportExportModules = [
  // E11ButtonModule,
  UiLoaderModule,
  UiLoaderLineModule,
  UiLoaderDotsModule,
  UiButtonModule,
  UiInputCheckboxModule,
  UiChartPercentageModule,
  UiBadgeModule,
  UiSideDrawerModule,
  UiTextareaModule,
  UiDatatableModule,
  UiPanelModule,
  UiPanelClassicModule,
  // REMEMBER: Toast and Notifications are root level
]

@NgModule({
  declarations: [],
  imports: [CommonModule, ...ImportExportModules],
  exports: [...ImportExportModules],
})
export class UiComponentLibraryModule {}
