import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core'
import { Permission } from '@consumer/app/config/permission.config'
import { INav } from '@consumer/app/interface/nav.interface'
import { SellerService } from '@consumer/app/services/seller.service'
import { selectors } from '@consumer/app/store/selectors'
import { PermissionService } from '@engineering11/permissions-web'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { select, Store } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { Observable, Subject } from 'rxjs'
import { takeUntil, tap } from 'rxjs/operators'
import { AppFeatures, IAppConsumerUser, OnLogOut, SellerApplicationStatus } from 'shared-lib'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  photoURL: any = null
  currentUser$: Observable<IAppConsumerUser | null> = this.store.pipe(select(selectors.getCurrentUser))

  features?: AppFeatures

  dropDownOpen = false

  hasAccountLink = false

  availableNavigation = new Array<INav>()
  sellMenuAvailable = false
  adminMenuAvailable = false
  loading = false

  destroy$ = new Subject()

  constructor(
    private store: Store,
    private permissionService: PermissionService,
    private sellerService: SellerService,
    private backdropService: E11BackdropService,
    private logger: NGXLogger,
    private elementRef: ElementRef
  ) {
    this.permissionService
      .userHasPermission(Permission.SELL_ITEMS)
      .pipe(takeUntil(this.destroy$))
      .subscribe(allowed => {
        if (allowed && !this.sellMenuAvailable) {
          this.availableNavigation.push(
            ...[
              {
                name: 'Manage Listings',
                isLabel: false,
                router: true,
                icon: 'people-arrows',
                permissions: [Permission.SELL_ITEMS],
                subs: [],
                notification: 0,
                link: '/sell/for-sale',
              },
              {
                name: 'Sales History',
                isLabel: false,
                router: true,
                icon: 'history',
                permissions: [Permission.SELL_ITEMS],
                subs: [],
                notification: 0,
                link: '/sell/sales-history',
              },
            ]
          )
          this.sellMenuAvailable = true
        }
      })
    this.permissionService
      .userHasPermission(Permission.MANAGE_USERS)
      .pipe(takeUntil(this.destroy$))
      .subscribe(allowed => {
        if (allowed && !this.adminMenuAvailable) {
          this.availableNavigation.push({
            name: 'Manage Users',
            isLabel: false,
            router: true,
            icon: 'people-arrows',
            permissions: [Permission.MANAGE_USERS],
            subs: [],
            notification: 0,
            link: '/account/users',
          })
          this.adminMenuAvailable = true
        }
      })
  }

  ngOnInit() {
    this.store
      .select(selectors.getFeatures)
      .pipe(
        takeUntil(this.destroy$),
        tap(features => this.logger.log({ features }))
      )
      .subscribe(features => (this.features = features))

    this.sellerService
      .getApplicationStatus()
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.hasAccountLink = response === SellerApplicationStatus.Accepted
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  async accountLink() {
    this.loading = true
    const { url } = await this.sellerService.getSellerPortalLink()
    this.loading = false
    window.open(url, '_blank')
  }

  signOut(): void {
    this.store.dispatch(new OnLogOut())
  }

  getInitial(value: string) {
    return value.match(/\b(\w)/g)?.join('') || ''
  }

  // Closers - Coffee is for closers only
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any[]) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement)
    if (!clickedInside) {
      this.dropDownOpen = false
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dropDownOpen = false
    }
  }
}
