import { createFeatureSelector, createSelector, select } from '@ngrx/store'

import { pipe } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { State, userEntitySelectors } from './user.reducer'
import jwt_decode from 'jwt-decode'
import { IE11Token } from '../../model/token.model'
import { _consumerOrError, __business_OrError } from './user.util'

export const getUserStore = createFeatureSelector<State>('user')

export const getUserEntities = createSelector(getUserStore, userEntitySelectors.selectAll)

export const getCurrentUser = createSelector(getUserStore, (store: State) => store.currentUser)
export const getToken = createSelector(getUserStore, (store: State) => store.token)

export const getCurrentUserconsumer = pipe(select(getCurrentUser), map(_consumerOrError))
export const getCurrentUser_business_ = pipe(select(getCurrentUser), map(__business_OrError))
/**
 * The userId or undefined if the user is undefined
 * Only re-emits if userId changes, not if user document changes.
 */
export const getCurrentUserId = pipe(
  select(getCurrentUser),
  map(user => user?.id),
  distinctUntilChanged<string | undefined>()
)

export const getCustomerKey$ = pipe(
  select(getCurrentUser),
  map(user => user?.customerKey),
  distinctUntilChanged<string | undefined>()
)

export const getCustomerKey = createSelector(getUserStore, (store: State) => store.currentUser != null && store.currentUser.customerKey)

export const getCurrentToken = createSelector(getUserStore, (store: State) => store.token)
export const getCurrentTokenDecoded = pipe(
  select(getCurrentToken),
  map(token => (token ? (jwt_decode(token) as IE11Token) : null))
)
export const isEmailVerified = pipe(
  getCurrentTokenDecoded,
  map(token => token?.email_verified),
  distinctUntilChanged()
)
