<div class="e11-grid e11-grid-cols-12 e11-gap-3 e11-mt-5">
  <div class="e11-col-span-12 md:e11-col-span-5">
    <e11-carousel [hasLightbox]="true" [lightboxSize]="'xl'" [hasCloser]="true" [photos]="item.photos ?? [] | map: 'url'"></e11-carousel>
  </div>

  <div class="e11-col-span-12 md:e11-col-span-5">
    <h2 class="e11-text-skin-primary e11-line-clamp-2 e11-word-break e11-font-medium">{{ item.title }}</h2>
    <p>{{ item.condition }}</p>
    <h3 class="e11-mb-1 e11-text-skin-dark">{{ 'Price' | translate }}: ${{ item.amountInCents || 0 | centsToDollars }}</h3>
    <p class="e11-text-sm e11-text-skin-light">{{ 'Quantity Available' | translate }} : {{ item.availableQuantity }}</p>
    <e11-button
      [value]="'Buy Now' | translate"
      (click)="buyNow.emit()"
      [disabled]="buyNowDisabled || context === itemViewContext.Preview"
      [disabledText]="'You cannot buy your own item' | translate"
    ></e11-button>
  </div>
  <div class="e11-col-span-12 md:e11-col-span-2 e11-text-center">
    <h3>{{ 'Seller Information' | translate }}</h3>
    <ng-container *ngIf="seller">
      <p>{{ seller.name }}</p>
      <p>{{ seller.websiteURL }}</p>
      <h5 *ngIf="seller.companyDescription">{{ 'About this seller' | translate }}</h5>
      <p>{{ seller.companyDescription }}</p>
      <e11-button [color]="'secondary'" [value]="'Message Seller' | translate" (click)="messageSeller.emit()" [disabled]="messageDisabled">
      </e11-button>
    </ng-container>
    <ng-container *ngIf="!seller">
      <e11-loader-dots [size]="'md'" [color]="'e11-bg-skin-primary-accent'"></e11-loader-dots>
    </ng-container>
  </div>
</div>

<e11-divider [color]="'e11-border-skin-primary'" [width]="100"></e11-divider>

<div *ngIf="item.description || item.longDescription" class="">
  <h3>{{ 'Item Description' | translate }}</h3>
  <p class="e11-word-break">{{ item.description }}</p>
  <p class="e11-word-break">{{ item.longDescription }}</p>
</div>

<div *ngIf="item.features?.length || item.attributes?.length" class="e11-grid e11-grid-cols-2 e11-border-b-2 e11-border-solid e11-pt-4 e11-pb-4">
  <div *ngIf="item.features?.length" class="e11-col-span-2 e11-mt-4 md:e11-mt-0 md:e11-col-span-1">
    <h4>{{ 'Features' | translate }}</h4>
    <ul>
      <li *ngFor="let feature of item.features">{{ feature }}</li>
    </ul>
  </div>
  <div *ngIf="item.attributes?.length" class="e11-col-span-2 e11-mt-4 md:e11-mt-0 md:e11-col-span-1">
    <h4>{{ 'Attributes' | translate }}</h4>
    <ul>
      <li *ngFor="let attribute of item.attributes">{{ attribute.name }}: {{ attribute.value }}</li>
    </ul>
  </div>
</div>

<div class="e11-grid e11-grid-cols-2 e11-border-b-2 e11-border-solid e11-pt-4 e11-pb-4">
  <div *ngIf="item.dimensions?.length" class="e11-col-span-2 e11-mt-4 md:e11-mt-0 md:e11-col-span-1">
    <h4>{{ 'Dimensions' | translate }}</h4>
    <ul>
      <li *ngFor="let dimension of item.dimensions">{{ dimension.name }} : {{ dimension.value }}{{ dimension.unitOfMeasurement }}</li>
    </ul>
  </div>

  <div class="e11-col-span-2 e11-mt-4 md:e11-mt-0 md:e11-col-span-1">
    <h4 class="">{{ 'Other Details' | translate }}</h4>

    <ul>
      <li *ngIf="item.brand" class="e11-word-break">{{ 'Brand' | translate }}: {{ item.brand }}</li>
      <li *ngIf="item.model" class="e11-word-break">{{ 'Model' | translate }}: {{ item.model }}</li>
      <li *ngIf="item.category" class="e11-word-break">{{ 'Category' | translate }}: {{ item.category }}</li>
      <li *ngIf="item.keywords?.length" class="e11-word-break">
        {{ 'Keywords' | translate }}:
        <span *ngFor="let keyword of item.keywords; let isLast = last"> {{ keyword }}<span *ngIf="!isLast">,</span></span>
      </li>
      <li *ngIf="item.manufacturer" class="e11-word-break">{{ 'Manufacturer' | translate }}: {{ item.manufacturer }}</li>
    </ul>
  </div>
</div>

<ng-container *ngIf="item._geoloc">
  <h3 class="e11-my-4">{{ 'Location' | translate }}</h3>
  <google-map [center]="item._geoloc" [zoom]="zoom" #googleMap>
    <map-marker #marker="mapMarker" [position]="item._geoloc"></map-marker>
  </google-map>
</ng-container>
