import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { SellerInformationService } from '@consumer/app/services/seller-information.service'
import { ICompleteProductItem, IProductItem, IPublicSellerInformation, ItemViewContext, LocalStorageService } from 'shared-lib'
import { IUser } from '@engineering11/user-shared'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss'],
})
export class ItemDetailComponent implements OnInit {
  @Input() context: ItemViewContext = ItemViewContext.Listing
  @Input() item!: IProductItem
  currentUser!: IUser
  seller?: IPublicSellerInformation
  destroy$ = new Subject<boolean>()
  zoom = 11
  messageDisabled = false
  buyNowDisabled = false
  itemViewContext = ItemViewContext

  @Output() buyNow = new EventEmitter()
  @Output() messageSeller = new EventEmitter()
  @Output() previewClick = new EventEmitter()

  constructor(private sellerInformationService: SellerInformationService, private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.currentUser = this.localStorageService.getItem('user')
    if (this.item && this.item.customerKey) {
      this.messageDisabled = (this.item.contactUserIds ?? []).length === 0 || this.item.contactUserIds![0] === this.currentUser.id
      this.buyNowDisabled = this.item.contactUserIds?.includes(this.currentUser.id) ?? false
      this.sellerInformationService
        .getPublicSellerInformation(this.item.customerKey)
        .pipe(takeUntil(this.destroy$))
        .subscribe(information => {
          this.seller = information
        })
    }
  }
}
