import { SellerApplicationStatus } from 'shared-lib'
import { SellerApplicationActions, SellerApplicationActionTypes } from './seller-application.actions'

export interface State {
  loading: boolean
  loadingSubmit: boolean
  applicationStatus: SellerApplicationStatus | null
}

export const initialState: State = {
  loading: false,
  loadingSubmit: false,
  applicationStatus: null,
}

export function reducer(state = initialState, action: SellerApplicationActions) {
  switch (action.type) {
    case SellerApplicationActionTypes.getSellerApplicationStatus:
      return { ...state, loading: true }
    case SellerApplicationActionTypes.getSellerApplicationStatusSuccess:
      return { ...state, loading: false, applicationStatus: action.payload.status }
    case SellerApplicationActionTypes.sendSellerApplication:
      return { ...state, loadingSubmit: true }
    case SellerApplicationActionTypes.sendSellerApplicationSuccess:
    case SellerApplicationActionTypes.sendSellerApplicationFailure:
      return { ...state, loadingSubmit: false }
    default:
      return state
  }
}
