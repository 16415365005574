// Framework
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule } from '@angular/forms'

// Platform specific
import { AppFooterComponent } from './components/footer/footer.component'
import { AppHeaderComponent } from './components/header/header.component'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { TranslateModule } from '@ngx-translate/core'
import { AsideleftComponent } from './components/aside-left/aside-left.component'
import { AppHeaderUnauthenticatedComponent } from './components/header-unauthenticated/header-unauthenticated.component'
import { AppHeaderSecondaryComponent } from './components/header-secondary/header-secondary.component'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'

// Modals
import { ModalPrivacyPolicyComponent } from './components/modals/privacy-policy/privacy-policy.component'
import { ModalTermsOfUseComponent } from './components/modals/terms-of-use/terms-of-use.component'
import { LetModule, PushModule } from '@ngrx/component'
import { UiInputErrorsModule } from '@engineering11/ui/ui-input-errors'
import { CloudflareStreamModule } from '@cloudflare/stream-angular'
// import { VideoRecordComponent } from '../first-impression/views/record/record.component'
import { SharedLibModule } from 'shared-lib'

import { FormModalContainerComponent } from './components/modal-container/form-modal-container.component'
import { UiTemplate } from './components/modal-container/ui-template'
import { ListComponent } from './components/list/list.component'
import { NgAisModule } from 'angular-instantsearch'
import { MessagingConfigurationProvider, MessagingModule } from '@engineering11/messaging-web'
import { ConversationCustomDataProvider, MessagingConfigFromApp } from '@consumer/app/config/messaging.config'
import { environment } from '@consumer/environments/environment'
import { ListItemComponent } from './components/list-item/list-item.component'
import { UiAlgCardComponent } from './components/algolia/ui-algolia-card/ui-alg-card.component'
import { SearchBarComponent } from './components/search-bar/search-bar.component'
import { FormZipCodeComponent } from './components/form-zip-code/form-zip-code.component'
import { MyLocationButtonComponent } from './components/my-location-button/my-location-button.component'
import { WebUtilityModule } from '@engineering11/web-utilities'
import { SideoverContainerComponent } from '@consumer/app/modules/_shared/components/sideover-container/sideover-container.component'
import { CurrentRefinementsComponent } from '@consumer/app/modules/_shared/components/algolia/current-refinements/current-refinements.component'
import { ItemDetailComponent } from './components/item-detail/item-detail.component'
import { GoogleMapsModule } from '@angular/google-maps'

const DeclareExportComponents = [
  AppFooterComponent,
  AppHeaderComponent,
  AppHeaderSecondaryComponent,
  AppHeaderUnauthenticatedComponent,
  AsideleftComponent,
  SearchBarComponent,

  CurrentRefinementsComponent,

  // Lists
  ListComponent,
  ListItemComponent,

  UiAlgCardComponent,

  // Modals
  ModalPrivacyPolicyComponent,
  ModalTermsOfUseComponent,

  // Share profile forms
  FormModalContainerComponent,
  UiTemplate,

  FormZipCodeComponent,
  MyLocationButtonComponent,
  SideoverContainerComponent,

  ItemDetailComponent,
  // GoogleMapsModule
]

@NgModule({
  declarations: [...DeclareExportComponents],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule, // For header login modal
    UiComponentLibraryModule,
    SharedLibModule.forRoot(environment),
    TranslateModule,
    LetModule,
    PushModule,
    CloudflareStreamModule,
    NgAisModule,
    WebUtilityModule,
    UiInputErrorsModule.forRoot({
      additional: {
        invalidDate: 'Please enter a valid date',
        invalidStartDate: 'Must be before end date',
        invalidEndDate: 'Must be after start date',
      },
    }),
    MessagingModule.forRoot({
      config: {
        provide: MessagingConfigurationProvider,
        useClass: MessagingConfigFromApp,
      },
      conversationCustomDataProvider: ConversationCustomDataProvider,
    }),
    NgxIntlTelInputModule,
    GoogleMapsModule,
  ],
  exports: [...DeclareExportComponents, UiComponentLibraryModule, TranslateModule, SharedLibModule, NgAisModule, MessagingModule],
})
export class SharedModule {}
