<!-- Config nav options later,  translate once final links are provided -->
<!-- Leave commented out nav items in place. We will add back or change. They will be needed. -->
<div class="header-secondary">
  <div class="row flex">
    <div class="col-xs-6 nopadding">
      <ul class="nav">
        <li *ngFor="let category of categories" (click)="navigate(category.name)">
          <a>{{ category.name }}</a>
        </li>
      </ul>
    </div>

    <div class="col-xs-6 nopadding text-right">
      <ul class="nav">
        <li [routerLink]="['/shop']" [routerLinkActive]="['active']">
          <a
            ><i class="fas fa-2x fa-shopping-cart"></i>
            <span class="text">{{ 'Shop' | translate }}</span>
          </a>
        </li>
        <li [routerLink]="['/sell']" [routerLinkActive]="['active']">
          <a
            ><i class="fas fa-2x fa-people-arrows"></i>
            <span class="text">{{ 'Sell' | translate }}</span>
          </a>
        </li>
        <li [routerLink]="['/account']" [routerLinkActive]="['active']">
          <a
            ><i class="fas fa-2x fa-user"></i>
            <span class="text">{{ 'My Account' | translate }}</span>
          </a>
        </li>
      </ul>
    </div>

    <!-- <lib-version-checker [environment]="_environment"></lib-version-checker> -->
  </div>
</div>
