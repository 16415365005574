// Framework
import { Component } from '@angular/core'
import { PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'

// Platform specific
import jwt_decode from 'jwt-decode'

import { Store } from '@ngrx/store'
import * as fromReduce from '@consumer/app/store/reducers'
import { OnLogIn } from 'shared-lib'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthenticationLoginComponent {
  redirectPath?: string
  constructor(public router: Router, private store: Store<fromReduce.user.State>) {}

  onError(error: Error): void {
    throw error
  }
  onForgottenPassword() {
    this.router.navigate(['/auth/recover'])
  }

  buildRedirectPath() {
    /**
     * The below checks if there is a deep link on the route, if so set
     * the redirect to that. The onboarding chain should always propagate
     * the deep link forward
     */
    const urlTree: UrlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    if (primary.segments.length > 1) {
      this.redirectPath = primary.segments[primary.segments.length - 1].path
    }
  }

  navigateToRegistration() {
    this.buildRedirectPath()
    this.router.navigate(['/registration/register', this.redirectPath])
  }

  async onSubmit(token: string): Promise<void> {
    this.buildRedirectPath()
    const decoded: any = jwt_decode(token)
    this.store.dispatch(new OnLogIn({ token, appUserId: decoded.appUserId, redirectPath: this.redirectPath }))
  }

  // sendAnalyticsEvent() {
  //   this.analyticsService.logEvent(Platform.dev, {
  //     module: Module.authentication,
  //     view: View.authenticationEmailVerification,
  //     analyticsEvent: AnalyticsEvents.login,
  //     userAction: UserAction.formSubmit,
  //   });
  // }
}
