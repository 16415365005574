import { IUser } from '@engineering11/user-shared'
import { IAppBusinessUser, IPlatformUser, SELLER_ROLE } from 'shared-lib'
/**
 * TODO: discriminate between business sellers and business non-sellers?
 * @param user
 * @returns whether the user is a seller
 */
export function isSeller(user: IPlatformUser): user is IAppBusinessUser {
  return user.roles.includes(SELLER_ROLE)
}
