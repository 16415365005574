import { Component, EventEmitter, forwardRef, Inject, OnInit, Optional, Output, ViewChild } from '@angular/core'
import { NgAisIndex, NgAisInstantSearch, TypedBaseWidget } from 'angular-instantsearch'
import connectCurrentRefinements, {
  CurrentRefinementsConnectorParams,
  CurrentRefinementsConnectorParamsItem,
  CurrentRefinementsConnectorParamsRefinement,
  CurrentRefinementsWidgetDescription,
} from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'
import { NgAisClearRefinements } from 'angular-instantsearch/clear-refinements/clear-refinements'

@Component({
  selector: 'alg-current-refinements',
  templateUrl: './current-refinements.component.html',
  styleUrls: ['./current-refinements.component.scss'],
})
export class CurrentRefinementsComponent
  extends TypedBaseWidget<CurrentRefinementsWidgetDescription, CurrentRefinementsConnectorParams>
  implements OnInit
{
  state!: CurrentRefinementsWidgetDescription['renderState'] // Rendering options
  @Output() hasRefinements = new EventEmitter<boolean>()
  @ViewChild('clearRefinements') clearRefinements!: NgAisClearRefinements

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('CurrentRefinementsComponent')
  }

  ngOnInit() {
    this.createWidget(connectCurrentRefinements, {})
    super.ngOnInit()
  }

  removeRefinement(item: CurrentRefinementsConnectorParamsItem, refinement: CurrentRefinementsConnectorParamsRefinement) {
    this.state.refine(refinement)
  }

  clear() {
    this.clearRefinements.state.refine()

    this.hasRefinements.emit(false)
  }
}
