// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ApiGatewayDomainBuilder, AppEngineDomainBuilder, IConsumerEnvironment, IConsumerServices, buildTenantConfig } from 'shared-lib'

const projectId = 'isd-demo-100'
const env = 'demo'

const appEngineBuilder = new AppEngineDomainBuilder(projectId)
const apiGatewayBuilder = new ApiGatewayDomainBuilder('1yd1h9bu')

export const environment: IConsumerEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyBPpZSC1YdmugUg6T2_3toRRiQKH6YL4C8',
    authDomain: 'isd-demo-100.firebaseapp.com',
    projectId: 'isd-demo-100',
    storageBucket: 'isd-demo-100.appspot.com',
    messagingSenderId: '153163308666',
    appId: '1:153163308666:web:318deb8e7bff564b77a5c3',
    measurementId: 'G-YP29LKPSN2',
  },
  services: {
    auth: apiGatewayBuilder.build('auth'),
    commerce: apiGatewayBuilder.build('commerce'),
    content: apiGatewayBuilder.build('content'),
    events: apiGatewayBuilder.build('events'),
    files: apiGatewayBuilder.build('files'),
    item: apiGatewayBuilder.build('product-item'),
    messaging: apiGatewayBuilder.build('messaging'),
    registration: apiGatewayBuilder.build('registration'),
    seller: apiGatewayBuilder.build('seller'),
    user: apiGatewayBuilder.build('user'),
    notifications: apiGatewayBuilder.build('notifications'),
  },

  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: false, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6Lf_5FAcAAAAAIkw3uoYixanodbLZtH5vf6jBhal',
  //tenantId: 'B2C-USER-bafvb',
  cdnCname: 'dev-content.instockdepot.com',
  algoliaAppId: 'T669KPJF0Q',
  algoliaSearchKey: 'cfed6e9ab147d5877c45f162677aa5c5',
  version: '75eca13',
  _business_EmailActionRoute: 'http://localhost:4610/#/auth/actions',
  passwordRecoveryEmailTemplateId: 'GQKsG5Wnv9sWJ9uY3aDf',
  emailVerificationEmailTemplateId: '2LKYubs8e8JqMm17XrZW',
  basePlatformHost: 'demo-admin.instockdepot.com',
  tenant: buildTenantConfig(env),
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
