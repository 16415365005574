// Framework
import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class AppFooterComponent implements OnInit {
  // @Input() sideNav: boolean = true;

  statusPrivacy: boolean = false
  statusTerms: boolean = false
  currentYear = new Date().getFullYear()

  constructor() {}

  ngOnInit(): void {}

  modalOpenPrivacy() {
    this.statusPrivacy = true
  }

  modalOpenTerms() {
    this.statusTerms = true
  }
}
