import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { SellerService } from '@consumer/app/services/seller.service'
import { isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService, isE11Error } from '@engineering11/web-api-error'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { filter, map, switchMap } from 'rxjs/operators'
import { NotificationTranslateService, getCurrentUser } from 'shared-lib'
import {
  GetSellerApplicationStatusSuccess,
  ListenSellerApplicationStatus,
  OnInitSellerApplication,
  SellerApplicationActionTypes,
  SendSellerApplication,
  SendSellerApplicationFailure,
  SendSellerApplicationSuccess,
} from './seller-application.actions'

@Injectable()
export class SellerApplicationEffects {
  user$ = this.store.select(getCurrentUser).pipe(filter(isNotNil))

  constructor(
    private actions$: Actions,
    private store: Store,
    private sellerService: SellerService,
    private router: Router,
    private notificationsService: NotificationTranslateService,
    private logger: NGXLogger,
    private errorHandler: E11ErrorHandlerService
  ) {}

  ngrxOnInitEffects(): Action {
    return { type: SellerApplicationActionTypes.onInitSellerApplication }
  }

  onInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OnInitSellerApplication>(SellerApplicationActionTypes.onInitSellerApplication),
      map(action => {
        return new ListenSellerApplicationStatus()
      })
    )
  })

  $onListenSellerApplicationStatus = createEffect(() =>
    this.actions$.pipe(
      ofType<ListenSellerApplicationStatus>(SellerApplicationActionTypes.getSellerApplicationStatus),
      switchMap(() => this.sellerService.getApplicationStatus()),
      map(status => new GetSellerApplicationStatusSuccess({ status }))
    )
  )

  $onSendSellerApplication = createEffect(() =>
    this.actions$.pipe(
      ofType<SendSellerApplication>(SellerApplicationActionTypes.sendSellerApplication),
      switchMap(action =>
        this.sellerService
          .sendSellerApplication(action.payload)
          .then(result => {
            this.logger.log('successfully submitted', result)
            this.router.navigate(['/shop'])
            this.notificationsService.popNotificationMessage(
              'Success!',
              'Congratulations! You are approved as a seller, your seller features can be found in the top right navigation.',
              'success',
              false
            )
            return new SendSellerApplicationSuccess()
          })
          .catch(error => {
            this.errorHandler.handleError(error, { alertUser: true })
            return new SendSellerApplicationFailure()
          })
      )
    )
  )
}
