import { Injectable } from '@angular/core'
import { BaseTenantBootstrapConfigProvider } from '@engineering11/tenant-bootstrap-web'
import { environment } from '@consumer/environments/environment'

@Injectable({ providedIn: 'root' })
export class ConsumerTenantBootstrapConfigProvider implements BaseTenantBootstrapConfigProvider {
  get config() {
    return {
      appConfigBaseURL: environment.tenant.appConfigBaseURL,
      defaultTenant: environment.tenant.defaultTenant,
      notFoundRoute: '/tenant-not-found',
    }
  }
}
