// Framework
import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { environment } from '@consumer/environments/environment'
import { Router } from '@angular/router'

// Platform specific

@Component({
  selector: 'app-header-secondary',
  templateUrl: './header-secondary.component.html',
  styleUrls: ['./header-secondary.component.scss'],
})
export class AppHeaderSecondaryComponent {
  showNewVersionWarning = false
  _environment = environment
  categories = [
    {
      id: 1,
      name: 'roofing',
    },
    {
      id: 2,
      name: 'siding',
    },
    {
      id: 3,
      name: 'paint',
    },
    {
      id: 4,
      name: 'masonry',
    },
    {
      id: 5,
      name: 'drywall',
    },
    {
      id: 6,
      name: 'plumbing',
    },
  ]
  constructor(private translateService: TranslateService, private router: Router) {}

  navigate(category: string) {
    // this.router.navigate(['/shop'], { queryParams: { category: category } })
    this.router.navigate(['/shop/search'], { queryParams: { category: category } })
  }
}
