<footer class="footer e11-bg-skin-app-bg e11-border-t-[32px] e11-border-skin-primary-accent e11-py-8">
  <div class="e11-container e11-mx-auto">
    <div class="e11-flex e11-grid e11-grid-cols-1 md:e11-grid-cols-2">
      <div class="e11-flex e11-mb-8 e11-mr-0 e11-justify-center md:e11-justify-start md:e11-mr-8 md:e11-mb-0">
        <img
          src="../../../../assets/images/logo/isd_header_logo_179x79.png"
          class="pointer"
          height="100"
          alt="{{ 'IN STOCK. ONLINE.' | translate }}"
          title="{{ 'IN STOCK. ONLINE.' | translate }}"
          aria-describedby="imgLogoinstockdepot"
        />
      </div>
      <div class="">
        <h3 class="e11-text-skin-dark">{{ 'IN STOCK. ONLINE.' | translate }}</h3>
        <p class="e11-mb-0">
          In Stock Depot is the only marketplace for the construction industry to buy and sell excess jobsite supplies and materials.
        </p>
      </div>
    </div>
  </div>

  <div class="e11-container e11-mx-auto">
    <div class="e11-w-full">
      <ui-divider color="" [width]="100"></ui-divider>
    </div>
  </div>

  <div class="e11-container e11-mx-auto">
    <div class="e11-w-full">
      <p class="e11-text-sm e11-text-skin-light">© {{ currentYear }} In Stock Depot, LLC. - {{ 'All rights reserved' | translate }}</p>
    </div>
  </div>
</footer>

<!-- Modals -->
<!-- Privacy modal -->
<modal-privacy-policy [status]="statusPrivacy"></modal-privacy-policy>
<!-- Terms modal -->
<modal-terms-of-use [status]="statusTerms"></modal-terms-of-use>
