// Framework
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// Platform specific
import { RegistrationComponent } from './registration.component'

// This 'DenyGuard' is used to block users from gettign back to the 'auth' module post login
// All 'user' management should be done in a 'users' module. 'auth' is not reusable for user management
import { FileNotFoundComponent } from '../_404/404.component'
import { redirectLoggedInToHomeGuard } from '@engineering11/auth-web'
import { RegistrationSingleStepComponent } from './views/registration-single-step/registration-single-step.component'
import { RegisterInviteComponent } from './views/register-invite/register-invite.component'

const routes: Routes = [
  {
    path: '',
    component: RegistrationComponent,
    ...redirectLoggedInToHomeGuard(),
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    children: [
      {
        path: '',
        component: RegistrationSingleStepComponent,
      },
      {
        path: 'register',
        component: RegistrationSingleStepComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'register/:deepLink',
        component: RegistrationSingleStepComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'register-invite/:code',
        component: RegisterInviteComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: '**',
        component: FileNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegistrationRoutingModule {}
