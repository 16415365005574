// Framework
import { Component, OnInit, ViewChild } from '@angular/core'
import { Title } from '@angular/platform-browser'

// Platform specific
import { UiNotificationsService, UiNotificationMessage } from '@engineering11/ui/ui-notifications'
import { E11ToastService, ToastDismissType } from '@engineering11/ui-lib/e11-toast'
import { TranslateService } from '@ngx-translate/core'
import { ConfigService } from './services/config.service'
import { UiBackdropService } from '@engineering11/ui/ui-backdrop'
import { WebpackTranslateLoader } from 'shared-lib'
import { hookToGlobalState } from './config/link-global.state'
import { Store } from '@ngrx/store'
import { environment } from '@consumer/environments/environment'
import { ConversationMessageStore } from '@engineering11/messaging-web'
export type ToastCTA = 'resendVerificationEmail'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  preserveWhitespaces: true,
})
export class AppComponent implements OnInit {
  theme: string = 'default'
  features: any[] = []
  settings = false

  _environment = environment

  constructor(
    private notificationsService: UiNotificationsService,
    private toastService: E11ToastService,
    private titleService: Title,
    private translateService: TranslateService,
    private configService: ConfigService,
    private backdropService: UiBackdropService,
    private messageStore: ConversationMessageStore,
    private store: Store
  ) {
    translateService.setDefaultLang('en')
    translateService.currentLoader = new WebpackTranslateLoader()
    this.theme = configService.config.theme
    this.features = configService.config.featureFlags
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle)
  }

  ngOnInit(): void {
    hookToGlobalState(this.messageStore.getState, 'messaging', this.store)

    this.setTitle('InStockDepot.com')
    //
    this.settings = this.isFeatureFlagEnabled('settings')

    // @Todo - What is the trigger for the toast?
    // this.toastInit()
  }
  reload() {
    location.reload()
  }

  isFeatureFlagEnabled(flag: any) {
    return this.features && this.features[flag]
  }

  close(value: boolean) {
    this.backdropService.backdropControl(false, 'null')
  }

  toastInit() {
    const cta: ToastCTA = 'resendVerificationEmail'
    this.toastService.popToastMessage({
      title: 'As a reminder...',
      bodyTitle: 'Verify your email now to claim your account!',
      bodyContent: 'It is very important that we verify who you are and that you have access to the email you provided.',
      type: '',
      dismissType: ToastDismissType.None,
      cta,
      ctaText: 'Resend verification email',
      icon: 'mail_outline',
      dismissOnRouteChange: false,
    })
  }
}
