// Framework
import { NgModule } from '@angular/core'
import { canActivate } from '@angular/fire/compat/auth-guard'
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router'

// Platform specific
import { redirectLoggedInToHome, superGuard, loggedInOnlyGuard } from '@engineering11/auth-web'
import { AboutComponent } from './components/about/about.component'

const routes: Routes = [
  {
    path: 'about',
    pathMatch: 'full',
    component: AboutComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'actions',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/email-actions/email-actions.module').then(m => m.EmailActionsModule),
      },
    ],
  },
  {
    path: 'sell',
    loadChildren: () => import('./modules/sell/sell.module').then(m => m.SellModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'shop',
    loadChildren: () => import('./modules/shop/shop.module').then(m => m.ShopModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'notification',
    loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'registration',
    loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule),
    ...canActivate(redirectLoggedInToHome('shop')),
  },

  {
    path: '',
    redirectTo: 'shop',
    pathMatch: 'full',
  },

  // { path: '404', component: FileNotFoundComponent },
  // { path: '**', redirectTo: '404' }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 120],
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  // providers: [{ provide: RouteReuseStrategy, useClass: AppReuseStrategy }],
})
export class AppRoutingModule {}
