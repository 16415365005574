<header class="header-landing">
  <div class="row flex">
    <div class="col-xs-8 nopadding flex-vertical-center" [routerLink]="['']">
      <img
        [src]="imgSrc"
        class="pointer"
        width="140"
        alt="{{ 'instockdepot - tagline' | translate }}"
        title="{{ 'instockdepot - tagline' | translate }}"
        aria-describedby="imgLogoinstockdepot"
      />
    </div>
  </div>
</header>
