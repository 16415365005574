import { Injectable } from '@angular/core'
import { environment } from '@consumer/environments/environment'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { E11ErrorHandlerService, isE11Error, toE11Error } from '@engineering11/web-api-error'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { NGXLogger } from 'ngx-logger'
import { Observable, of } from 'rxjs'
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators'
import { getCurrentUser, IPlatformUser, ISD_ERRORS, ISellerApplicationDto, SellerApplicationStatus, LocalStorageService } from 'shared-lib'
import { isNotNil } from '@engineering11/utility'
import { isSeller } from '../util/user-type.util'
import { SellerApplicationRepository } from '../repository/seller-application.repository'

@Injectable({ providedIn: 'root' })
export class SellerService {
  user$ = this.store.select(getCurrentUser).pipe(filter(isNotNil))

  private restClient: RestApiClient
  constructor(
    private localStorageService: LocalStorageService,
    private backdropService: E11BackdropService,
    private logger: NGXLogger,
    private store: Store,
    private sellerApplicationRepository: SellerApplicationRepository
  ) {
    this.restClient = new RestApiClient({
      baseUrl: environment.services.seller,
      token: () => localStorageService.getItem('token'),
    })
  }

  sendSellerApplication(sellerApplication: ISellerApplicationDto) {
    return this.restClient.post('application', sellerApplication).toPromise()
  }

  getApplicationStatus(): Observable<SellerApplicationStatus> {
    return this.user$.pipe(
      take(1),
      switchMap(user => {
        if (isSeller(user)) return of(SellerApplicationStatus.Accepted)
        return this.sellerApplicationRepository
          .listenByCustomerKey(user.customerKey)
          .pipe(map(application => (application ? application.status : SellerApplicationStatus.NotFound)))
      }),
      distinctUntilChanged()
    )
  }

  async registerPaymentAccount() {
    this.backdropService.backdropDisplay(true, 'dark')
    const returnURL = new URL('shop', location.origin)
    const refreshURL = new URL('shop?registration=true', location.origin)
    try {
      const response = await this.getPaymentAccountRegistrationLink(returnURL.toString(), refreshURL.toString())
      if (response && response.url) {
        const { url } = response
        location.href = url
      }
    } catch (err) {
      throw toE11Error(err, {
        type: ISD_ERRORS.SELLER_REGISTRATION_FAILED,
        message: 'Seller Registration Failed',
        title: 'Seller Registration Failed',
      })
    } finally {
      this.backdropService.backdropDisplay(false, '')
    }
  }

  getPaymentAccountRegistrationLink(returnURL: string, refreshURL: string) {
    return this.restClient
      .post<{ url: string } | null>('application/registration-link', {
        returnURL,
        refreshURL,
      })
      .pipe(map(response => response.data))
      .toPromise()
      .then(response => response!)
  }

  getSellerPortalLink() {
    return this.restClient
      .get<{ url: string }>(`seller/account/link`)
      .pipe(map(response => response.data))
      .toPromise()
      .then(response => response!)
  }
}
