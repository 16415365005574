import { Inject, Injectable } from '@angular/core'
import { deepCopy } from '@engineering11/utility'
import { ERROR_TRACKER_TOKEN, IErrorTracker } from '@engineering11/web-api-error'
import { combineLatest, Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { ICompleteProductItem, IProductContentItem, ItemStatus, productItemContentType, ProductItemRepository } from 'shared-lib'
import { ItemContentService } from './item/item-content.service'

/**
 * Intent: The Quantity of the published product item will change as people buy it.
 * We didn't want to mutate the original content item, so we are using this service as an intermediate step to construct a "View Model" containing the information we want to show the seller
 * TODO: Consider constructing an ISellerProductItemVM type and adding originalQuantity if we need to display it
 */

@Injectable({
  providedIn: 'root',
})
export class SellerItemService {
  constructor(
    private itemContentService: ItemContentService,
    private productItemRepository: ProductItemRepository,
    @Inject(ERROR_TRACKER_TOKEN) private errorTracker: IErrorTracker
  ) {}

  getAllProductItems(customerKey: string) {
    return this.itemContentService.getAllOfType(customerKey, productItemContentType).pipe(
      switchMap(items => {
        if (!items.length) return of([])
        const updatedItem$s = items.map(item => this.updateItemQuantity(item))
        return combineLatest(updatedItem$s)
      })
    )
  }

  private updateItemQuantity(contentItem: IProductContentItem): Observable<IProductContentItem> {
    const productItem = this.productItemRepository.get(contentItem.document.id)
    return productItem.pipe(map(product => this.buildUpdatedItem(contentItem, product)))
  }

  private buildUpdatedItem(contentItem: IProductContentItem, publishedProductItem?: ICompleteProductItem) {
    if (contentItem.document.status !== ItemStatus.Published) {
      return contentItem
    }
    if (!publishedProductItem) {
      this.errorTracker.error('Missing product item for published content document', { contentItem, publishedProductItem })
      return contentItem
    }
    const itemCopy = deepCopy(contentItem)
    const status = publishedProductItem.quantity === 0 ? ItemStatus.SoldOut : publishedProductItem.status
    itemCopy.document = { ...itemCopy.document, quantity: publishedProductItem.quantity, status }
    return itemCopy
  }
}
