import { Injectable } from '@angular/core'
import { CONSUMER_USER_ROLES } from '@instockdepot/user-shared'
import { Store } from '@ngrx/store'
import { IUserRolesProvider } from '@engineering11/permissions-web'
import { getCurrentUser } from 'shared-lib'
import { Observable } from 'rxjs'
import { isNotNil } from '@engineering11/utility'
import { filter, map } from 'rxjs/operators'

export enum Permission {
  SELL_ITEMS = 'SELL_ITEMS',
  MANAGE_USERS = 'MANAGE_USERS',
}

export const PERMISSION_TO_ROLE: { [key in Permission]: CONSUMER_USER_ROLES[] } = Object.freeze({
  [Permission.SELL_ITEMS]: [CONSUMER_USER_ROLES.SELLER],
  [Permission.MANAGE_USERS]: [CONSUMER_USER_ROLES.ADMINISTRATOR],
})

@Injectable({ providedIn: 'root' })
export class UserRolesProvider implements IUserRolesProvider {
  constructor(private store: Store) {}
  getRoles(): Observable<string[]> {
    return this.store.select(getCurrentUser).pipe(
      filter(isNotNil),
      map(user => user.roles)
    )
  }
}
