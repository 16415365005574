// Framework
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// Platform specific
import { HomeStartComponent } from './views/start/start.component'
import { HomeView2Component } from './views/view2/view2.component'
import { FileNotFoundComponent } from '../_404/404.component'
import { HomeComponent } from './home.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeStartComponent,
      },
      {
        path: 'start',
        component: HomeStartComponent,
      },
      {
        path: 'view2',
        component: HomeView2Component,
      },
      {
        path: '**',
        component: FileNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
